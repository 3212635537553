/* You can add global styles to this file, and also import other style files */

@import "~bootstrap-icons/font/bootstrap-icons.css";


.ck-editor {
    height: 100% !important;
    max-height: 100% !important;
    display: flex;
    flex-direction: column;
}

/* Body of the CKEditor where you input content */
.ck-editor__main {
    max-height: 100%;
    overflow-y: hidden;
    flex-grow: 1;
}

/* Another div nested inside .ck-editor__main */
.ck-editor__editable {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
}